import axios from "axios";
import API_URL from './config'

axios.defaults.baseURL = API_URL;

const ApiService = {
    tokens: data => axios.post("/api/OpenVidu/tokens", data).then(res => res.data),
    destroy: data => axios.post("/api/OpenVidu/destroy", data).then(res => res.data),
    leave: data => axios.post("/api/OpenVidu/leave", data).then(res => res.data),
    join: data => axios.post("/api/OpenVidu/join", data).then(res => res.data).catch(err => { throw err.response.data }),
};

export default ApiService;