import React, { Fragment } from 'react';
import { Switch, Route } from "react-router-dom";
import Session from './containers/session'

import GlobalStyle from './global-styles';
import './global-styles.css';

const App = ({ location, isLoading }) => (
    <Fragment>
        <Switch>
            <Route
                location={location}
                path="/"
                exact
                component={Session}
            />
        </Switch>
        <GlobalStyle />
    </Fragment>
)

export default App;