import { createGlobalStyle } from 'styled-components';
import 'antd/dist/antd.css';

const GlobalStyle = createGlobalStyle`
@import url(https://fonts.googleapis.com/css?family=Roboto:300);
body {
  background: #f0975b; /* fallback for old browsers */
  background: -webkit-linear-gradient(right, #f0975b, #f0975b);
  background: -moz-linear-gradient(right, #f0975b, #f0975b);
  background: -o-linear-gradient(right, #f0975b, #f0975b);
  background: linear-gradient(to left, #f0975b, #f0975b);
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.form .message {
  margin: 15px 0 0;
  color: #b3b3b3;
  font-size: 12px;
}
.form .message a {
  color: #f0975b;
  text-decoration: none;
}
`;

export default GlobalStyle;